import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

export const commonColors = {
    white: '#fff',
    black: '#000',
    primary: '#5c6473',
};

const CustomThemeProvider = (props: PropsWithChildren<Record<string, unknown>>) => {
    const darkMode = false;
    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            primary: {
                main: '#5c6473',
            },
        },
        background: '#000000',
        font: '#EEEEEE',
        commonColors,
    });
    return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

declare module '@mui/material/styles' {
    interface Theme {
        background: string;
        font: string;
        commonColors: { [key: string]: string };
    }

    interface ThemeOptions {
        background: string;
        font: string;
        commonColors: { [key: string]: string };
    }
}

export default CustomThemeProvider;
