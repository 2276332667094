import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminRoute from 'routes/AdminRoute';
import { default as PrivateRoute } from 'routes/PrivateRoute';

const Admin = React.lazy(() => import('pages/Admin/Admin'));
const Inventory = React.lazy(() => import('pages/Inventory/Inventory'));
const Home = React.lazy(() => import('pages/Home/Home'));

const AppRoutes = () => {
    return (
        <Routes>
            <Route path={'/'} Component={Home} />
            <Route path={'/inventory'} Component={PrivateRoute}>
                <Route path={'/inventory'} Component={Inventory} />
            </Route>
            <Route path={'/admin'} Component={AdminRoute}>
                <Route path={'/admin'} Component={Admin} />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
